import { getVersionInfo } from './version';
import config from './config';

const logLevels = {
    ERROR: 0,
    WARN: 1,
    INFO: 2,
    DEBUG: 3
};

const formatMessage = (level, message) => {
    const timestamp = new Date().toISOString();
    const versionInfo = getVersionInfo();
    return `[${timestamp}] [${level}] [${versionInfo}] ${message}`;
};

const logger = {
    error: (...args) => {
        if (logLevels[config.logLevel] >= logLevels.ERROR) {
            console.error(formatMessage('ERROR', args.join(' ')));
        }
    },
    warn: (...args) => {
        if (logLevels[config.logLevel] >= logLevels.WARN) {
            console.warn(formatMessage('WARN', args.join(' ')));
        }
    },
    info: (...args) => {
        if (logLevels[config.logLevel] >= logLevels.INFO) {
            console.info(formatMessage('INFO', args.join(' ')));
        }
    },
    debug: (...args) => {
        if (logLevels[config.logLevel] >= logLevels.DEBUG) {
            console.debug(formatMessage('DEBUG', args.join(' ')));
        }
    }
};

export default logger;