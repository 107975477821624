import React from 'react';
import './LandingScreen.css';

const LandingScreen = () => {
    return (
        <div className="landing-screen">
            <h1>Welcome to Ease My Migraine</h1>
            <p>Track and manage your migraines with ease using our Telegram Mini App.</p>
            <p>To get started, please chat with our Telegram bot:</p>
            <a
                href="https://t.me/EaseMyMigraineBot"
                target="_blank"
                rel="noopener noreferrer"
                className="cta-button"
            >
                Chat with EaseMyMigraine Bot
            </a>
        </div>
    );
};

export default LandingScreen;
