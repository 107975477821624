class TelegramService {
    constructor() {
        this.tg = process.env.REACT_APP_ENV === 'development' ? this.getMockTg() : window.Telegram.WebApp;
    }

    getMockTg() {
        return {
            ready: () => { },
            expand: () => { },
            BackButton: {
                onClick: (callback) => {
                    this.backButtonCallback = callback;
                },
                offClick: () => {
                    this.backButtonCallback = null;
                },
            },
            showPopup: ({ title, message, buttons }) => {
                console.log('Mock Popup:', { title, message, buttons });
            },
            showAlert: (message) => {
                console.log('Mock Alert:', message);
            },
            close: () => {
                console.log('Mock: Closing Telegram Web App');
            },
            initData: 'query_id=AAGpQJEyAAAAAKlAkTJuro7F&user=%7B%22id%22%3A848380073%2C%22first_name%22%3A%22Jan%22%2C%22last_name%22%3A%22%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728223312&hash=53f29ef0d5235a030115221458be609c321deaafe579eb5f7890e8a09f3db6cc',
            initDataUnsafe: {
                auth_date: "1728223312",
                hash: "53f29ef0d5235a030115221458be609c321deaafe579eb5f7890e8a09f3db6cc",
                query_id: "AAGpQJEyAAAAAKlAkTJuro7F",
                user: {
                    "id": 848380073,
                    "first_name": "Jan",
                    "last_name": "",
                    "language_code": "en",
                    "allows_write_to_pm": true
                }
            }
        };
    }

    ready() {
        this.tg.ready();
    }

    expand() {
        this.tg.expand();
    }

    setBackButton(callback) {
        this.tg.BackButton.onClick(callback);
    }

    removeBackButton() {
        this.tg.BackButton.offClick();
    }

    showPopup(options) {
        this.tg.showPopup(options);
    }

    showAlert(message) {
        this.tg.showAlert(message);
    }

    close() {
        this.tg.close();
    }

    getInitData() {
        return {
            initData: this.tg.initData,
            user: this.tg.initDataUnsafe.user,
        }
    }
}

export default new TelegramService();