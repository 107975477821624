const config = {
  development: {
    apiUrl: 'http://localhost:5001',
    logLevel: 'DEBUG',
  },
  production: {
    apiUrl: 'https://easemymigraine.com',
    logLevel: 'INFO',
  },
};

const environment = process.env.REACT_APP_ENV || 'development';

export default config[environment];