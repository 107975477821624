import axios from 'axios';
import config from './config';
import logger from './logger';

const generateAndStoreToken = async (telegramInitData) => {
    try {
        const response = await axios.post(`${config.apiUrl}/auth/token`, {
            initData: telegramInitData.initData,
        });

        const { token } = response.data;
        localStorage.setItem('authToken', token);
        return token;
    } catch (error) {
        logger.error('Error generating token:', error);
        throw error;
    }
};

export default generateAndStoreToken;