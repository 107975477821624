import axios from 'axios';
import React, { useState, useEffect } from 'react';
import generateAndStoreToken from './generateAndStoreToken';
import ConfirmSubmit from './components/ConfirmSubmit/ConfirmSubmit';
import HasMigraine from './components/HasMigraine/HasMigraine';
import LandingScreen from './components/LandingScreen/LandingScreen';
import PainIntensitySelector from './components/PainDescriptors/PainIntensitySelector';
import PainkillerInput from './components/PainDescriptors/PainkillerInput';
import PainkillersEffectivnessSelector from './components/PainDescriptors/PainkillersEffectivnessSelector';
import PainLocationInput from './components/PainDescriptors/PainLocationInput';
import PainOnsetSelector from './components/PainDescriptors/PainOnsetSelector';
import PainTypeSelector from './components/PainDescriptors/PainTypeSelector';
import { UserContext } from './UserContext';
import config from './config';
import logger from './logger';
import telegramService from './telegramService';
import './global.css';
import './styles/theme.css';

const MainSteps = {
    HAS_MIGRAINE: 'HAS_MIGRAINE',
    MIGRAINE_DESCRIPTION: 'MIGRAINE_DESCRIPTION',
    CONFIRM_SUBMIT: 'CONFIRM_SUBMIT',
};

const DescriptionSteps = {
    PAIN_INTENSITY: 'PAIN_INTENSITY',
    PAIN_LOCATION: 'PAIN_LOCATION',
    PAIN_TYPE: 'PAIN_TYPE',
    PAIN_OFFSET: 'PAIN_OFFSET',
    PAINKILLER: 'PAINKILLER',
    PAINKILLERS_EFFECTIVNESS: 'PAINKILLERS_EFFECTIVNESS',
};

const migraineDescriptionSteps = [
    { id: DescriptionSteps.PAIN_INTENSITY, component: PainIntensitySelector },
    { id: DescriptionSteps.PAIN_LOCATION, component: PainLocationInput },
    { id: DescriptionSteps.PAIN_TYPE, component: PainTypeSelector },
    { id: DescriptionSteps.PAIN_OFFSET, component: PainOnsetSelector },
    { id: DescriptionSteps.PAINKILLER, component: PainkillerInput },
    { id: DescriptionSteps.PAINKILLERS_EFFECTIVNESS, component: PainkillersEffectivnessSelector },
];

const App = () => {
    const [currentMainStep, setCurrentMainStep] = useState(MainSteps.HAS_MIGRAINE);
    const [currentDescriptionStep, setCurrentDescriptionStep] = useState(0);
    const [painData, setPainData] = useState({
        hasMigraine: null,
        painData: {
            intensity: null,
            location: '',
            painkiller: '',
            painkillersEffectivness: '',
            painOffset: '',
            painType: '',
        },
    });
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        // Initialize Telegram Web App.
        telegramService.ready();

        // Expand the Web App to full screen
        telegramService.expand();

        // Get user data
        const initData = telegramService.getInitData();
        logger.debug(initData);

        if (initData) {
            setUserId(initData.user.id.toString());
            generateAndStoreToken(initData)
                .then(newToken => {
                    logger.debug('Obtained token!', newToken);
                    setToken(newToken);
                    axios.interceptors.request.use(config => {
                        config.headers.Authorization = `Bearer ${newToken}`;
                        return config;
                    });
                })
                .catch(error => {
                    logger.error('Failed to generate token:', error);
                    // Handle error (e.g., show error message to user)
                });
        }

        // Clean up function
        return () => {
        };
    }, []);

    useEffect(() => {
        // Set up back button functionality
        telegramService.setBackButton(() => {
            if (currentMainStep === MainSteps.MIGRAINE_DESCRIPTION && currentDescriptionStep > 0) {
                navigateDescription('back');
            } else if (currentMainStep === MainSteps.CONFIRM_SUBMIT) {
                setCurrentMainStep(MainSteps.MIGRAINE_DESCRIPTION);
            } else {
                telegramService.close();
            }
        });

        return () => {
            telegramService.removeBackButton();
        };
    }, [currentMainStep, currentDescriptionStep]);

    const navigateDescription = (direction, data = null) => {
        if (data !== null) {
            setPainData(prevData => ({
                ...prevData,
                painData: { ...prevData.painData, ...data },
            }));
        }

        if (direction === 'next') {
            const newPosition = currentDescriptionStep + 1;
            if (newPosition < migraineDescriptionSteps.length) {
                setCurrentDescriptionStep(newPosition);
            } else {
                setCurrentMainStep(MainSteps.CONFIRM_SUBMIT);
            }
        } else if (direction === 'back') {
            const newPosition = currentDescriptionStep - 1;
            if (newPosition >= 0) {
                setCurrentDescriptionStep(newPosition);
            } else {
                setCurrentMainStep(MainSteps.HAS_MIGRAINE);
            }
        }
    };

    const handleHasMigraine = (hasMigraine) => {
        setPainData({ ...painData, hasMigraine });
        if (hasMigraine) {
            setCurrentMainStep(MainSteps.MIGRAINE_DESCRIPTION);
        } else {
            resetPainData();
            setCurrentMainStep(MainSteps.CONFIRM_SUBMIT);
        }
    };

    const handleSubmit = async () => {
        try {
            const dataToSubmit = {
                hasMigraine: painData.hasMigraine,
                painData: painData.painData,
            };
            await axios.post(`${config.apiUrl}/api/pain`, dataToSubmit);
            setCurrentMainStep(MainSteps.HAS_MIGRAINE);
            resetPainData();

            telegramService.showPopup({
                title: "Success",
                message: "Your migraine data has been submitted successfully.",
                buttons: [{ type: "close" }]
            });
        } catch (error) {
            logger.error('Error saving data:', error);
            telegramService.showAlert("Error submitting data. Please try again.");
        }
    };

    const resetPainData = () => {
        setPainData(prevData => ({
            hasMigraine: prevData.hasMigraine,
            painData: {
                intensity: null,
                location: '',
                painkiller: '',
                painkillersEffectivness: '',
                painOffset: '',
                painType: '',
            },
        }));
        setCurrentDescriptionStep(0);
    };

    const renderStep = () => {
        switch (currentMainStep) {
            case MainSteps.HAS_MIGRAINE:
                return <HasMigraine onAnswer={handleHasMigraine} />;
            case MainSteps.MIGRAINE_DESCRIPTION:
                const CurrentDescriptionStep = migraineDescriptionSteps[currentDescriptionStep].component;
                return (
                    <CurrentDescriptionStep
                        data={painData.painData}
                        onNext={(data) => navigateDescription('next', data)}
                        onBack={() => navigateDescription('back')}
                    />
                );
            case MainSteps.CONFIRM_SUBMIT:
                return (
                    <ConfirmSubmit
                        painData={{ userId, ...painData }}
                        onSubmit={handleSubmit}
                        onBack={() => {
                            if (Object.values(painData.painData).some(value => value !== null && value !== '')) {
                                setCurrentMainStep(MainSteps.MIGRAINE_DESCRIPTION);
                            } else {
                                setCurrentMainStep(MainSteps.HAS_MIGRAINE);
                            }
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <UserContext.Provider value={token}>
            <div className="App">
                {token ? renderStep() : <LandingScreen />}
            </div>
        </UserContext.Provider>
    );
};

export default App;